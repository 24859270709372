<template>
  <r-page>
    <template #page-content>
      <div class="columns is-multiline">
        <div class="column" />
        <div class="column">
          <div class="box payment-success-card">
            <div class="column is-12">
              <img
                src="../../../public/images/payment-success.svg"
                class="center"
              />
              <h2 class="pt-4 has-text-centered payment-success-title">
                Payment Successful
              </h2>
              <img src="../../../public/images/amico.svg" class="center" />
              <p class="has-text-centered payment-success-content">
                Your payment has been successful
                <br />
                You can access the apps by click the button below
              </p>
              <b-button
                expanded
                type="is-primary"
                class="payment-success-button"
                @click.native="redirectTo()"
              >
                View Invoice
              </b-button>
            </div>
          </div>
        </div>
        <div class="column" />
      </div>
    </template>
  </r-page>
</template>

<script>
export default {
  methods: {
    redirectTo() {
      window.location.href = `${process.env.VUE_APP_SPACER_URL}/create-company?order=${this.$route.query.order}`
    },
  },
}
</script>
